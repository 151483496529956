import React from "react";
import "../../common/componentsStyles/tooltip.styl";
import { ProjectSwitcher } from "../../projectSwitcher";
import { createRoot } from "react-dom/client";

if (document.getElementById("root")) {
  const render = () => {
    const rootElement = document.getElementById("root");
    const root = createRoot(rootElement);
    root.render(<ProjectSwitcher defaultProject={"copacabana"} />);
  };
  
  render();
}
